/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://orvsp.nudge.sasaki.com';

export const HEAP_ID = '';

export const FORMSPREE_URL = 'https://formspree.io/f/xzblpyyv';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: '', href: '#welcome' },
    { text: 'Vision', href: '#vision' },
    { text: 'Team', href: '#team' },
    { text: 'Process', href: '#process' },

    { text: 'Share Your Ideas', href: '#share' },
    { text: 'ORVSP Plan Updates', href: '#updates' },

];
