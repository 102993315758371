/**
 * MapSequence
 *
 * This is the map building sequence that remains fixed in the browser window while
 * the user is scrolling. As each Section passes the screens vertical midpoint,
 * the next map image is loaded into the frame.
 */

import { useEffect, useState } from 'react';
import { henshu, useHenshu } from '@strategies/henshu';

import { useContext } from '../../context';


/**
 * Images
 */


import Map1 from './assets/1_regional diagram_update_1.jpg';
import Map2 from './assets/2_river photo-13.png';
import Map3 from './assets/3_Regional Map 0615 Natural Thread_topographic-01.jpg';
import Map4 from './assets/4_Regional Map EcoRegion_For Website.jpg';
import Map5 from './assets/5_Regional Map River_For Website.jpg';
import Map6 from './assets/6_Informational Board_The River-for website2.jpg';
import Map7 from './assets/7_Regional Map Community_For Website.jpg';
import Map8 from './assets/8_Regional Map Connectivity_For Website.jpg';

const MAPS = [
    undefined,
    Map1,
    Map2,
    Map3,
    Map4,
    Map5,
    Map6,
    Map7,
    Map8,
];

export default function MapSequence() {
    const { bindTo } = useHenshu();
    const { map } = useContext();
    const [modalActive, setModalActive] = useState<boolean>(false);

    return <>
        <div className="MapSequence">
            <div className={`maps`}>
                {MAPS.map((src, i) => (
                    <div key={`${i}-${src}`} className={`map ${map! === i ? 'active' : ''}`}>
                        <img src={src} alt="" />
                    </div>
                ))}
            </div>
        </div>
    </>;
}
